import React from "react";
import { projectsData } from "../data";
import Project from "./Project";

const Projects = () => {
    const projects = projectsData;
    return (
        <div>
            <section className="grid lg:grid-cols-3 gap-y-12 lg:gap-x-8 lg:gap-y-8">
                {projects.map((item) => {
                    return <Project key={item.id} item={item} />;
                })}
            </section>
        </div>
    );
};

export default Projects;
