import React from "react";
// import components
import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Skills from "./components/Skills";
import Work from "./components/Work";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import BackToTopBtn from "./components/BackToTopBtn";
import Socials from "./components/Socials";

const App = () => {
    return (
        <div className="relative overflow-x-hidden">
            <Socials />
            <Header />
            <Hero />
            <About />
            <Skills />
            <Work />
            <Services />
            <Contact />
            <Footer />
            <BackToTopBtn />
        </div>
    );
};

export default App;
