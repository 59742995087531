import React from "react";
import { social } from "../data";

const Socials = () => {
    return (
        <div className="hidden md:block fixed left-8 top-[40vh] bg-zinc-50 bg-opacity-10 w-10 h-auto rounded-full mt-0 z-10">
            <ul className="flex flex-col h-auto gap-8 py-6 justify-evenly ">
                {social.map((item, index) => {
                    return (
                        <li
                            className="flex justify-center items-center text-greenAccent hover:text-accent transition-all duration-300 ease-in-out"
                            key={index}
                        >
                            <a className="text-base " href={item.href}>
                                {item.icon}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Socials;
