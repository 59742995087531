import React from "react";
import { Link } from "react-scroll";
import Image from "../assets/img/about.webp";

const About = () => {
    return (
        <section id="about" className="section bg-secondary">
            <div className="container mx-auto">
                <div className="flex flex-col xl:flex-row gap-24">
                    <img
                        className="object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded-2xl"
                        src={Image}
                        alt=""
                    />
                    <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
                        <div className="flex flex-col">
                            <h2 className="text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-5 before:-top-[5rem] before:-left-36 before:hidden before:lg:block">
                                Mario Polchert
                            </h2>
                            <p className="mb-4 text-accent">
                                Web Developer / Designer
                            </p>
                            <hr className="mb-8 opacity-5" />
                            <p className="mb-8">
                                Welcome! I'm Mario, specializing in web
                                development and design with a customer-first
                                approach. I use proven models to craft
                                user-friendly websites that attract and retain
                                your audience, turning them into customers.
                                Focused on clarity and usability, I provide
                                straightforward solutions that align with your
                                business goals. Let's enhance your digital
                                presence with proven models that drive real
                                growth.
                            </p>
                        </div>
                        <button className="btn btn-md bg-accent hover:bg-accent-hover transition-all">
                            <Link
                                to="contact"
                                smooth={true}
                                duration={500}
                                offset={-70}
                                className="transition-all duration-300 cursor-pointer"
                            >
                                Contact me
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
