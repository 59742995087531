//  icons
import {
    FiGithub,
    FiLayout,
    FiSettings,
    FiPenTool,
    FiTag,
    FiMail,
    FiLinkedin,
} from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";

// projects images
import Project1 from "./assets/img/projects/happy-colors.webp";
import Project2 from "./assets/img/projects/delicious-kitchen.webp";
import Project3 from "./assets/img/projects/top-shop.webp";

// skills images
import SkillImg1 from "./assets/img/skills/html5.png";
import SkillImg2 from "./assets/img/skills/css3.png";
import SkillImg3 from "./assets/img/skills/js.png";
import SkillImg4 from "./assets/img/skills/reactjs.png";
import SkillImg5 from "./assets/img/skills/nextjs.png";
import SkillImg6 from "./assets/img/skills/nodejs.png";
import SkillImg7 from "./assets/img/skills/git.png";
import SkillImg8 from "./assets/img/skills/figma.png";

// navigation
export const navigation = [
    {
        name: "home",
        href: "home",
    },
    {
        name: "about",
        href: "about",
    },
    {
        name: "work",
        href: "work",
    },
    {
        name: "services",
        href: "services",
    },
    {
        name: "contact",
        href: "contact",
    },
];

// social
export const social = [
    {
        icon: <FiGithub />,
        href: "",
    },
    {
        icon: <FiLinkedin />,
        href: "",
    },
    {
        icon: <FaXTwitter />,
        href: "",
    },
];

// projects
export const projectsData = [
    {
        id: "1",
        image: Project1,
        name: "Basic",
        url: "https://happycolors.mariopolchert.com/",
        category:
            "A basic web design package is ideal for small businesses, those looking to establish their online presence with a simple website, or they already have one, but it's outdated and doesn't bring cutsomers.",
        services: [],
    },
    {
        id: "2",
        image: Project2,
        name: "Standard",
        url: "https://delicious-kitchen.netlify.app/",
        category:
            "Standard considers an advanced web design package as the best option. This pricing tier is a step up from the basic level because it covers web design projects with extensive requirements. ",
        services: [],
    },
    {
        id: "3",
        image: Project3,
        name: "Premium",
        url: "topshop.mariopolchert.com",
        category:
            "Premium are custom made, modern websites requiring a complex build and cost more due to the extensive customizations and additional integrations",
        services: [],
    },
];

// skill
export const skills = [
    {
        image: SkillImg1,
    },
    {
        image: SkillImg2,
    },
    {
        image: SkillImg3,
    },
    {
        image: SkillImg4,
    },
    {
        image: SkillImg5,
    },
    {
        image: SkillImg6,
    },
    {
        image: SkillImg7,
    },
    {
        image: SkillImg8,
    },
];

// services
export const services = [
    {
        icon: <FiLayout />,
        name: "Web Design",
        description:
            "I design captivating, user-friendly websites tailored to your brand, enhancing engagement and conversions. Each design uniquely embodies your brand's identity, ensuring a memorable online presence.",
    },
    {
        icon: <FiSettings />,
        name: "Web Development",
        description:
            "I develop robust, responsive websites using the latest technologies, ensuring fast performance and a seamless user experience. From e-commerce to web applications, I deliver tailored digital solutions.",
    },
    {
        icon: <FiPenTool />,
        name: "Branding",
        description:
            "I develop distinctive branding strategies, from logo design to messaging, to highlight your business's unique value. My approach ensures your brand connects deeply with your audience, building recognition and loyalty.",
    },
    {
        icon: <FiTag />,
        name: "SEO",
        description:
            "I enhance your website's search engine rankings, boosting visibility and organic reach. Through strategic keywords and technical SEO, your site becomes more discoverable and competitive, leading to improved user engagement and conversions.",
    },
];

// contact
export const contact = [
    {
        icon: <FiMail />,
        title: "Have a question?",
        subtitle: "I am here to help you.",
        description: "mario@mariopolchert.com",
    },
];
