import React, { useState } from "react";
import { navigation } from "../data";
import { XCircleIcon } from "@heroicons/react/outline";
import { MenuIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

const NavMobile = () => {
    const [isOpen, setIsOpen] = useState(false);

    const circleVariants = {
        hidden: {
            scale: 0,
        },
        visible: {
            scale: 180,
            transition: {
                type: "spring",
                stiffness: 160,
                damping: 60,
            },
        },
    };

    const ulVariants = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {
                delay: 0.1,
            },
        },
    };

    return (
        <nav className="relative">
            <div
                onClick={() => setIsOpen(true)}
                className="cursor-pointer text-white"
            >
                <MenuIcon className="w-16 h-16 text-greenAccent" />
            </div>

            <motion.div
                variants={circleVariants}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                className="w-4 h-4 rounded-full bg-tertiary fixed top-0 right-0 "
            ></motion.div>

            <motion.ul
                variants={ulVariants}
                initial="hidden"
                animate={isOpen ? "visible" : ""}
                className={`${
                    isOpen ? "right-0" : "-right-full"
                } fixed top-0 bottom-0 w-full flex flex-col justify-center items-center transition-all duration-300 overflow-hidden`}
            >
                <div
                    onClick={() => setIsOpen(false)}
                    className="cursor-pointer absolute top-8 right-8"
                >
                    <XCircleIcon
                        className="w-16 h-16 text-greenAccent"
                        onClick={() => setIsOpen(false)}
                    />
                </div>

                {navigation.map((item, index) => {
                    return (
                        <li className="mb-8" key={index}>
                            <Link
                                to={item.href}
                                activeClass="active"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                className="text-xl cursor-pointer capitalize"
                                onClick={() => setIsOpen(false)}
                            >
                                {item.name}
                            </Link>
                        </li>
                    );
                })}
            </motion.ul>
        </nav>
    );
};

export default NavMobile;
