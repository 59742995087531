import React, { useState, useEffect } from "react";

import { animateScroll as scroll } from "react-scroll";

import { ChevronUpIcon } from "@heroicons/react/outline";

const BackToTopBtn = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            return window.scrollY > 600 ? setShow(true) : setShow(false);
        });
    });

    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    return (
        show && (
            <button
                onClick={() => scrollToTop()}
                className=" bg-accent w-10 h-10 hover:bg-accent-hover text-white rounded-full fixed right-6 bottom-16 cursor-pointer flex justify-center items-center transition-all"
            >
                <ChevronUpIcon
                    className="w-6 h-6 text-white"
                    onClick={scrollToTop}
                />
            </button>
        )
    );
};

export default BackToTopBtn;
