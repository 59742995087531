import React from "react";
import { Link } from "react-scroll";
import Logo from "../assets/img/logo_teal_border_512.png";
import SocialsFooter from "./SocialsFooter";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-tertiary py-12">
            <div className="container mx-auto">
                <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 items-center justify-between">
                    <div>
                        <Link to="home" smooth={true}>
                            <img
                                src={Logo}
                                alt="mp logo"
                                className="w-24 h-auto cursor-pointer"
                            />
                        </Link>
                    </div>

                    <SocialsFooter />

                    <p className="text-center">
                        {" "}
                        All rights reserved &copy; {currentYear}{" "}
                    </p>
                    <p className="text-center ">
                        {" "}
                        Made by{" "}
                        <a href="https://mariopolchert.com" className="">
                            <span className="text-greenAccent hover:text-accent transition-all duration-300 ease-in-out font-bold">
                                mp
                            </span>
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
