import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import Logo from "../assets/img/logo_teal_border_512.png";
import Nav from "../components/Nav";
import NavMobile from "../components/NavMobile";

const Header = () => {
    const [bg, setBg] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            return window.scrollY > 50 ? setBg(true) : setBg(false);
        });
    });

    return (
        <header
            className={`${
                bg ? "bg-tertiary h-20" : "h-24"
            } flex fixed top-0 w-full text-white z-10 transition-all duration-300 items-center `}
        >
            <div className="container mx-auto h-full flex items-center justify-between">
                <Link to="home" smooth={true}>
                    <img
                        src={Logo}
                        alt="logo"
                        className={`${
                            bg ? "w-12" : "w-16"
                        } h-auto transition-all duration-300 cursor-pointer`}
                    />
                </Link>
                <div className="hidden lg:block">
                    <Nav />
                </div>
                <div className=" lg:hidden">
                    <NavMobile />
                </div>
            </div>
        </header>
    );
};

export default Header;
